import { createApi } from '@reduxjs/toolkit/query/react';

import type { ChildPrediction, TenderType } from './types';
import { baseQuery } from '../stateUtils';
import { setTenderItems } from '../tenderItems/tenderItemsState';
import { createInitialTreeStructure, mapOverItems } from '../tenderItems/treeDataUtils';

const recurisve = (data: ChildPrediction[]) => {
	const normalizedItems: { entities: { [key: string]: any }; ids: string[] } = {
		entities: {},
		ids: []
	};

	const innerFunction = (
		data: ChildPrediction[],
		parentUUID: undefined | string,
		parentPosition: number
	) => {
		data.forEach((childPrediction: ChildPrediction, index: number) => {
			const tender: TenderType = {
				isOffer: childPrediction.offer,
				isTenderGroup: childPrediction.tenderItemType === 'bo-q-ctgies',
				itemPredictionId: childPrediction.id,
				longText: childPrediction.longText,
				numOfEiwa: 0,
				numOfHawa: 0,
				offer: false,
				parentItem: parentUUID,
				parentUUID,
				position: parentPosition ? `${parentPosition}.${index + 1}` : index + 1,
				probability: childPrediction.offerProbability,
				productCategory: childPrediction.productCategoryPredictions[0]?.categoryName || '',
				productPredictions: childPrediction.productPredictions,
				quantity: childPrediction.quantity,
				refinementId: '',
				shortText: childPrediction.shortText,
				sorting: 0,
				status: '',
				tid: childPrediction.tenderItemId!,
				unit: childPrediction.unit,
				userItemRefinementCategories: [],
				userItemRefinementSuppliers: [],
				userRefinementProducts: []
			};
			if (childPrediction.tenderItemId) {
				normalizedItems.ids.push(childPrediction.tenderItemId as string);
				normalizedItems.entities[childPrediction.tenderItemId as string] = tender;
				if (childPrediction.childPredictions.length) {
					innerFunction(
						[...childPrediction.childPredictions].sort((a, b) =>
							a.listOrderIndex > b.listOrderIndex ? 1 : -1
						),
						childPrediction.tenderItemId as string,
						tender.position as number
					);
				}
			}
		});
	};
	innerFunction(
		[...data].sort((a, b) => (a.listOrderIndex > b.listOrderIndex ? 1 : -1)),
		'',
		0
	);
	return normalizedItems;
};

const aiPredictionsApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			fetchAIPredictions: builder.query({
				async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
					const { data } = await queryFulfilled;
					const normalizedItems = recurisve(data.childPredictions);
					const { treeStructure, parentlessIds } = createInitialTreeStructure(normalizedItems);
					const normalizedData = {
						[id]: {
							data: treeStructure,
							docRefinementId: '',
							fetchingStatus: 'LOADING',
							ids: normalizedItems.ids,
							parentlessIds,
							status: 'DONE',
							totalCheckedItems: 0,
							totalItems: data.itemCount,
							totalUncheckedItems: 0,
							treeData: parentlessIds?.length ? mapOverItems(treeStructure, parentlessIds) : []
						}
					};
					dispatch(setTenderItems(normalizedData as any));
				},
				query: ({ id, akt }) => `services/aipredictions2/api/predictions/ensure/for/${akt}/${id}`
			}),
			fetchAIPredictionsForItems: builder.query({
				query: ({ predictionId, tenderId }) =>
					`services/aipredictions2/api/item-predictions/from/${predictionId}/${tenderId}`
			}),
			fetchAIProductCategoryPrediction: builder.query({
				query: (id) => `services/aipredictions2/api/product-category-predictions/${id}`
			}),
			fetchAIProductsPredictions: builder.query({
				query: (id) => `services/aipredictions2/api/product-predictions/${id}`
			}),
			fetchAIProductsPredictionsBulk: builder.query({
				async queryFn(ids, _queryApi, _extraOptions, baseQuery) {
					const response = await Promise.all(
						ids.map((id: string) =>
							baseQuery(`services/aipredictions2/api/product-predictions/${id}`)
						)
					);

					const products = response.map((product: any) => product.data);
					return { data: products };
				}
			})
		};
	},
	reducerPath: 'aiPredictions'
});

export const {
	useFetchAIPredictionsQuery,
	useFetchAIProductsPredictionsQuery,
	useFetchAIProductsPredictionsBulkQuery,
	useFetchAIProductCategoryPredictionQuery
} = aiPredictionsApi;

export const selectAiProductsPredictions =
	aiPredictionsApi.endpoints.fetchAIProductsPredictionsBulk.select;

export { aiPredictionsApi };
