import './polyfills';
import type { AuthenticationResult, EventMessage } from '@azure/msal-browser';
import { EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
// import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import { msalInstance } from './authConfig';
// @ts-ignore
// eslint-disable-next-line import/extensions
import './i18n.ts';
import './global.scss';
import { store } from './state';
import 'react-toastify/dist/ReactToastify.css';
import './appInsight';

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
	msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback(async (event: EventMessage) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		const payload = event.payload as AuthenticationResult;
		const { account } = payload;
		msalInstance.setActiveAccount(account);
	}
	if (
		[EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS].includes(event.eventType) &&
		event.payload
	) {
		localStorage.setItem('HULK-CURRENT-NTUSER', (event.payload as any).account.username);
		localStorage.setItem('HULK-CURRENT-LOGGED-USER', JSON.stringify(event.payload));
		localStorage.setItem(
			'HULK-AKT-ACCESS-RIGHTS',
			(event.payload as any).account.idTokenClaims.roles.join(',')
		);

		await fetch(`${process.env.REACT_APP_API_URL}api/authenticationResult2JWT`, {
			body: JSON.stringify(event.payload),
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'POST'
		})
			.then((response) => {
				if (response.status === 403) {
					msalInstance.logoutRedirect({
						account: msalInstance.getAccountByHomeId(accounts[0]?.homeAccountId),
						postLogoutRedirectUri: `${process.env.REACT_APP_URL}logout`
					});
					localStorage.clear();
					sessionStorage.clear();
					return null;
				}

				return response.json();
			})
			.then((response: any) => {
				localStorage.setItem('jhi-authenticationToken', response.id_token);
			});
	}
	if (
		[EventType.ACQUIRE_TOKEN_FAILURE].includes(event.eventType) &&
		window.location.pathname !== '/'
	) {
		window.location.replace(window.location.origin);
	}
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<MsalProvider instance={msalInstance}>
		<Provider store={store}>
			{/* <StrictMode> */}
			<App />
			{/* </StrictMode> */}
		</Provider>
	</MsalProvider>
);
