import type { AccountInfo } from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { lazy, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import {
	BrowserRouter as Router,
	createBrowserRouter,
	createRoutesFromElements,
	Navigate,
	Route,
	RouterProvider
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { loginRequest } from './authConfig';
import AppLoader from './components/AppLoader';
import AppWrapper from './components/AppWrapper';
import CachingComponent from './components/CachingComponent';
import ErrorFallback from './components/ErrorFallback';
import type { GraphApi } from './interfaces';
import { getMSGraph } from './msGraph';
import getRoutes from './routes';
import { useLazyFetchMembersOfLoggedInUserGroupsQuery } from './state';

const BasePath = lazy(() => import('./screens/BasePath/BasePath'));
const BlurryScreen = lazy(() => import('./components/MockedComponents/BlurryScreen'));
const ExportAnSap = lazy(() => import('./screens/ExportAnSap/ExportAnSap'));
const ErrorElement = lazy(() => import('./components/ErrorElement'));
const OnePager = lazy(() => import('./screens/OnePager/OnePager'));
const ProjectCreation = lazy(() => import('./screens/ProjectCreation/ProjectCreation'));
const ProjectList = lazy(() => import('./screens/ProjectList/ProjectList'));
const ProjectOverview = lazy(() => import('./screens/ProjectOverview/ProjectOverview'));
const UnauthorizedUser = lazy(() => import('./screens/UnauthorizedUser/UnauthorizedUser'));
const ProjectFiles = lazy(() => import('./screens/ProjectFiles/ProjectFiles'));
const AiAnalysis = lazy(() => import('./screens/AiAnalysis'));
const ArticleAndOffer = lazy(() => import('./screens/ArticleAndOffer'));
const AddProductsToTenderItem = lazy(() => import('./components/AddProductsToTenderItem'));
const EditWarningModal = lazy(() => import('./components/EditWarningModal'));
const EditTenderProductModal = lazy(() => import('./components/EditTenderProductModal'));
const DeleteItemConfirmationModal = lazy(() => import('./components/DeleteItemConfirmationModal'));
const SupplierEmailModal = lazy(() => import('./components/SupplierEmailModal/SupplierEmailModal'));

const App = () => {
	const { t } = useTranslation();

	const {
		ONE_PAGER_AI_ANALYSIS,
		ONE_PAGER_SUPPLIER_INQUIRIES,
		ONE_PAGER_CUSTOMER,
		ONE_PAGER_DATEIN,
		ONE_PAGER_EXPORT_AN_SAP,
		ONE_PAGER_OVERVIEW,
		PROJECTS_CREATE,
		PROJECTS
	} = getRoutes(t);

	const [refetch] = useLazyFetchMembersOfLoggedInUserGroupsQuery();

	const { instance, inProgress } = useMsal();
	const [graphData, setGraphData] = useState<undefined | GraphApi>(undefined);
	useEffect(() => {
		if (!graphData && inProgress === 'none') {
			// @ts-ignore
			getMSGraph()
				.then((response: unknown) => {
					setGraphData(response as GraphApi);
					refetch('allHulkUsers');
				})
				.catch((e: any) => {
					if (e instanceof InteractionRequiredAuthError) {
						instance.acquireTokenRedirect({
							...loginRequest,
							account: instance.getActiveAccount() as AccountInfo
						});
					}
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inProgress, graphData, instance]);

	const router = createBrowserRouter(
		createRoutesFromElements([
			<Route
				key="firstRoute"
				element={
					<>
						<BasePath
							givenName={graphData?.givenName}
							preferredLanguage={graphData?.preferredLanguage}
							surname={graphData?.surname}
						/>
						<ToastContainer autoClose={15000} />
					</>
				}
				errorElement={<ErrorElement />}
				path="/"
			>
				<Route element={<ProjectList />} path={PROJECTS} />
				<Route element={<ProjectCreation />} path={`${PROJECTS_CREATE}/:projectId?`} />
				<Route element={<OnePager />} path={`${PROJECTS}/:projectId/`}>
					<Route element={<ProjectOverview />} path={`${ONE_PAGER_OVERVIEW}`} />
					<Route element={<AiAnalysis />} path={`${ONE_PAGER_AI_ANALYSIS}/:tenderItemId?`}>
						<Route element={<AddProductsToTenderItem />} path="preview" />
						<Route element={<EditWarningModal />} path=":warning" />
						<Route element={<AddProductsToTenderItem />} path="addProducts/:contentUUID" />
						<Route element={<EditTenderProductModal />} path="edit/:productId" />
						<Route element={<DeleteItemConfirmationModal />} path="delete/:productId" />
					</Route>
					<Route
						element={<ArticleAndOffer />}
						path={`${ONE_PAGER_SUPPLIER_INQUIRIES}/:supplierId?`}
					>
						<Route element={<AddProductsToTenderItem />} path="preview/:tenderItemId" />
						<Route element={<SupplierEmailModal />} path="newMessage/:inquireId?" />
					</Route>
					<Route element={<ProjectFiles />} path={`${ONE_PAGER_DATEIN}`} />
					<Route element={<ExportAnSap />} path={`${ONE_PAGER_EXPORT_AN_SAP}`} />
					<Route element={<BlurryScreen img="kunde.png" />} path={`${ONE_PAGER_CUSTOMER}`} />
				</Route>
			</Route>,
			<Route key="catchAllRoute" element={<Navigate to="/" />} path="*" />
		])
	);
	const jhiAuthToken = localStorage.getItem('jhi-authenticationToken');
	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<AuthenticatedTemplate>
				<AppWrapper>
					{graphData && jhiAuthToken && <CachingComponent />}
					{graphData && jhiAuthToken ? <RouterProvider router={router} /> : <AppLoader />}
				</AppWrapper>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Router>
					<UnauthorizedUser />
				</Router>
			</UnauthenticatedTemplate>
		</ErrorBoundary>
	);
};

export default App;
