import { createApi } from '@reduxjs/toolkit/query/react';

import type { GenericObjectType } from '../../types';
import { setAiProducts } from '../search/searchAiPredictionsProductsSlice';
import { baseQuery } from '../stateUtils';
import type { ProductProposal } from '../supplierProductProposal/supplierProductProposal';
import { supplierProductProposalApi } from '../supplierProductProposal/supplierProductProposal';
import { updateTenderItemChildren } from '../tenderItems/tenderItemsState';
import { toggleTenderItemsTreeExportColumn } from '../ui/uiSlice';

const productMasterDataApi = createApi({
	baseQuery,
	endpoints(builder) {
		return {
			fetchHulkProductsByIds: builder.query<
				{ entities: { [key: string]: any }; ids: string[] },
				string
			>({
				forceRefetch({ currentArg, previousArg }) {
					return currentArg !== previousArg;
				},
				async onQueryStarted(args, { queryFulfilled, dispatch }) {
					try {
						const { data } = await queryFulfilled;
						dispatch(setAiProducts({ data }));
					} catch {
						// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
					}
				},
				query: (ids) => `services/productmasterdata/api/hulk-products-by-ids?ids=${ids}`,
				transformResponse: (response: any) =>
					response.reduce(
						(acc: any, curr: any) => {
							acc.entities[curr.id as string] = curr;
							if (!acc.ids.includes(curr.id)) {
								acc.ids.push(curr.id);
							}
							return acc;
						},
						{
							entities: {} as GenericObjectType<any>,
							ids: [] as string[]
						}
					)
			}),
			fetchProductsForTender: builder.query<
				{ entities: { [key: string]: any }; ids: string[] },
				{
					ids: string;
					tid: string | undefined;
					contentUUID: string;
					docRefinementId: string;
				}
			>({
				forceRefetch({ currentArg, previousArg }) {
					return currentArg !== previousArg;
				},
				async onQueryStarted(
					{ tid, contentUUID, docRefinementId },
					{ queryFulfilled, getState, dispatch }
				) {
					try {
						const { data } = await queryFulfilled;
						// @ts-ignore
						const tenderItems = getState().tenderItems.tenderItems[contentUUID];
						// @ts-ignore
						const currentExportSelectedVersion = getState().ui.isTenderItemsTreeExportColumn;
						const currentTenderItem = tenderItems.data[tid];
						const newChildren = data.ids.reduce((acc: any, id: any) => {
							const obj1 = data.entities[id];
							const matchingObj = currentTenderItem.productAssigments.filter(
								(obj2: any) => obj2.productId === obj1.maraId
							);
							if (matchingObj?.length) {
								matchingObj.forEach((matchingObj: any) => {
									acc.push({
										...obj1,
										...matchingObj,
										unit: obj1.unitOfMeasure
									});
								});
							}
							return acc;
						}, []);
						const { data: supplierProductProposals } = await dispatch(
							supplierProductProposalApi.endpoints.fetchSupplierProductProposals.initiate(
								docRefinementId
							)
						);
						const filteredProductProposals = (
							supplierProductProposals.productProposals as unknown as ProductProposal[]
						)?.filter(
							(product: ProductProposal) =>
								product.itemRefinementId === currentTenderItem.refinementId
						);
						const newChildrenWithProposals = filteredProductProposals.reduce(
							(acc: any, product: any) => {
								const matchingObjs = newChildren.filter(
									(child: any) => child.productId === product.productId
								);
								if (matchingObjs.length) {
									matchingObjs.forEach((matchingObj: any) => {
										acc.push({
											...product,
											editableStatus: matchingObj.editableStatus,
											eiwaHawaType: matchingObj.eiwaHawaType,
											longText: product?.longText ? product.longText : matchingObj.longText,
											materialCategory: product.materialCategory,
											productAssigmentId: matchingObj.tid,
											shortText: product?.shortText ? product.shortText : matchingObj.shortText,
											tid: product.tid,
											unit: product.unit ? product.unit : matchingObj.unit
										});
									});
								}
								return acc;
							},
							[]
						);
						const entities = newChildrenWithProposals.reduce((acc: any, obj: any) => {
							acc[obj.tid] = {
								...obj,
								isTenderGroup: false,
								parentItem: tid,
								priceType: obj.supplierPriceType,
								product: true,
								productCategory: obj.materialCategory,
								ttItemId: obj.productId
							};
							return acc;
						}, {});

						if (currentExportSelectedVersion && currentExportSelectedVersion?.includes(tid)) {
							dispatch(
								toggleTenderItemsTreeExportColumn([
									...currentExportSelectedVersion,
									...newChildrenWithProposals.map((children: any) => children.tid)
								])
							);
						}
						dispatch(
							updateTenderItemChildren({
								contentUUID,
								entities,
								tenderId: tid as string
							})
						);
					} catch {
						// TODO add logic for informing users that error has happened ?toaster? if PO makes a ticket later on
					}
				},
				query: ({ ids }) => `services/productmasterdata/api/hulk-products-by-ids?ids=${ids}`,
				transformResponse: (response: any) =>
					response.reduce(
						(acc: any, curr: any) => {
							acc.entities[curr.id as string] = curr;
							if (!acc.ids.includes(curr.id)) {
								acc.ids.push(curr.id);
							}
							return acc;
						},
						{
							entities: {} as GenericObjectType<any>,
							ids: [] as string[]
						}
					)
			})
		};
	},
	reducerPath: 'productMasterData'
});

export const { useFetchHulkProductsByIdsQuery, useLazyFetchProductsForTenderQuery } =
	productMasterDataApi;
export const selectHulkProducts = productMasterDataApi.endpoints.fetchHulkProductsByIds.select;

export { productMasterDataApi };
